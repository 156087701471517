import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Switch to : <a href="https://www.progyaneducations.in"> Progyan Educations.in</a></h1>
    </div>
  );
}

export default App;

// Path: prog-live/src/index.js